html {
  font-size: 90%;
  font-family: "Roboto Condensed", sans-serif;
  color: #474747;
}
html p, html h6 {
  font-size: 1rem;
  margin: 1rem 0;
  font-weight: 300;
}
html h5 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
html h4 {
  font-size: 1.96rem;
  margin: 1rem 0;
}
html h3 {
  font-size: 2.74rem;
  margin: 1rem 0;
}
html h2 {
  font-size: 3.84rem;
  margin: 1rem 0;
}
html h1 {
  font-size: 5.38rem;
  margin: 1rem 0;
}
html body {
  margin: 0;
}
html a {
  color: #474747;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.our-product {
  background-color: #ffffff;
  width: 100%;
  padding: 2rem 0;
  position: relative;
  top: 12vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.our-product .our-product__h2 {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.our-product .our-product__list {
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}/*# sourceMappingURL=Services.css.map */