@use "../scss_partials/_colors" as *;
@use "../scss_partials/_global" as *;
//@use "../../assets/gallery/image_1.jpg" as background_image;


.grow {
  min-width: 100%;
  &__button {
    display: flex;
    background-color: white;
    border: 2px, solid, black;
    min-width: 25%;
    min-height:10vh;
    padding: 0.5rem;
    margin:0.125rem,0;
    justify-content: center;
    align-items: center;
  }
}
.buttons {
  @include flex-box(row, space-around, center, nowrap); 
  width: 100%;
}

.hero {
  @include flex-box(column, space-between, center, wrap);  
  //background: linear-gradient(45deg, $neutral-color-2, $neutral-color-1);
    //@include flex-box(row, space-between,center, nowrap);
    box-sizing: border-box;
    text-align: center;
    color: $neutral-color-1;
    padding: 5vh 5% 1vh 5%;
    background-image: url("../../assets/Salon_Image.jpg");
    transition: opacity 1s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 80vh;
    position: relative;
    top: 12vh;
    &__descr {
      background-color: rgba(0,0,0, 0.6); /* Black w/opacity/see-through */
      width: 90%;
      padding: 1rem;
      @include flex-box(row, center, center, wrap);  
    }
    &__img {
      //background-image: url("../../assets/gallery/image_1.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      -webkit-filter: blur(2px);
      filter: blur(2px);
    height: 70vh;
    }
}

.noMargin {
  margin: 0;
}

.back-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  .back-video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .back-video {
    width: auto;
    height: 100%;
  }
}
.links {
  color: $accent-color-2;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: $neutral-color-1;
  font-size: 1rem;
  background-color: white;
}
