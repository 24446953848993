@use "../scss_partials/_global" as *;
@use "../scss_partials/_colors" as *;

footer {
    background-color: $neutral-color-2;
    padding-bottom: 2rem;
    padding-top: 2.5rem;
    width: 100%;
    position: relative;
    top: 12vh;
    .footer__list {
        @include flex-box(row, space-around,flex-start, wrap);
        list-style: none;
        padding-left: 0;
        margin: 0;
        
        &--subtitle {
            color: $accent-color-2;
            font-size: 0.75rem;
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
        &__card {
            @include flex-box(column, space-between,flex-start, nowrap);
            height: 100%;
            //width: 100%;
            padding: 1rem;
            
            &--centered {
                @include flex-box(column,space-between,center, nowrap);
                height: 100%;
                padding: 1rem;
            }

            &__sublist {
                @include flex-box(row, space-between,flex-start, nowrap);
                list-style: none;
                padding-left: 0;
                li {
                    padding: 0 2rem 0 0;
                }
                
                &--icons { 
                    margin-top: 2.5rem;
                    padding-left: 0;
                    @include flex-box(row, space-between,flex-start, nowrap);
                    list-style: none;
                    img {
                        height: 2rem;
                    }
                    li {
                        padding: 0 1.5rem;
                    }
                }
            }
        }
    }
    
}
@media (max-width: 800px) {
    footer {
        .footer__list {
            @include flex-box(column,space-between,center, wrap);
            &__card {
                border: 1px solid black;
                border-radius: 5px;
                width: 90%;
                margin: 1rem 0;
                @include flex-box(column, center,center, wrap);

                &--centered {
                    border: 1px solid black;
                    border-radius: 5px;
                    width: 90%;
                    margin: 1rem 0;
                }
            }
        }
        
    }
  }

  .fullwidth {
    width: 100%;
  }
  .prompt {
    width: 80%;
    font-size: 1rem;
    padding: 2rem;
    justify-content: center;
    border: 1px, solid, black;
    margin:auto;
        
}
.anchor_format {
    font-style: italic;
    color: blue;
    text-decoration: underline;
    text-decoration-color: blue;
}
