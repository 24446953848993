html {
  font-size: 90%;
  font-family: "Roboto Condensed", sans-serif;
  color: #474747;
}
html p, html h6 {
  font-size: 1rem;
  margin: 1rem 0;
  font-weight: 300;
}
html h5 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
html h4 {
  font-size: 1.96rem;
  margin: 1rem 0;
}
html h3 {
  font-size: 2.74rem;
  margin: 1rem 0;
}
html h2 {
  font-size: 3.84rem;
  margin: 1rem 0;
}
html h1 {
  font-size: 5.38rem;
  margin: 1rem 0;
}
html body {
  margin: 0;
}
html a {
  color: #474747;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.grow {
  min-width: 100%;
}
.grow__button {
  display: flex;
  background-color: white;
  border: 2px, solid, black;
  min-width: 25%;
  min-height: 10vh;
  padding: 0.5rem;
  margin: 0.125rem, 0;
  justify-content: center;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  text-align: center;
  color: #ffffff;
  padding: 5vh 5% 1vh 5%;
  background-image: url("../../assets/Salon_Image.jpg");
  transition: opacity 1s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 80vh;
  position: relative;
  top: 12vh;
}
.hero__descr {
  background-color: rgba(0, 0, 0, 0.6); /* Black w/opacity/see-through */
  width: 90%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.hero__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: blur(2px);
  height: 70vh;
}

.noMargin {
  margin: 0;
}

.back-video {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  .back-video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .back-video {
    width: auto;
    height: 100%;
  }
}
.links {
  color: #4d0c74;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-color: #ffffff;
  font-size: 1rem;
  background-color: white;
}/*# sourceMappingURL=Hero.css.map */