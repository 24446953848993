html {
  font-size: 90%;
  font-family: "Roboto Condensed", sans-serif;
  color: #474747;
}
html p, html h6 {
  font-size: 1rem;
  margin: 1rem 0;
  font-weight: 300;
}
html h5 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
html h4 {
  font-size: 1.96rem;
  margin: 1rem 0;
}
html h3 {
  font-size: 2.74rem;
  margin: 1rem 0;
}
html h2 {
  font-size: 3.84rem;
  margin: 1rem 0;
}
html h1 {
  font-size: 5.38rem;
  margin: 1rem 0;
}
html body {
  margin: 0;
}
html a {
  color: #474747;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 5%;
  box-sizing: border-box;
  width: 100vw;
  height: 12vh;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
}
.nav-bar__items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0 0.5rem;
}
.nav-bar__items--selected {
  text-decoration: underline;
  text-decoration-color: #4d0c74;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.5rem;
  padding: 0 0.5rem;
}
.nav-bar--anchor {
  color: #ececec;
}

.highlight {
  color: #4d0c74;
  font-weight: 700;
  width: 70px;
}

.border {
  border: 2px, solid, #ffffff;
}/*# sourceMappingURL=Header.css.map */