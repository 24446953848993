@use '../../scss_partials/_colors.scss' as *;
@use '../../scss_partials/_global.scss' as *;

.our-product__card {
    width: 19rem; /*I removed 50px because it was a bit wide */
    height: 29rem;
   
   border-radius: 1rem;
   box-shadow: 0px 5px 9px -1px;
   @include flex-box (column,space-between,center,nowrap);
   background-color: $neutral-color-1;
   margin: 2.5rem 2.5rem;
}

.our-product__card--img {
   margin: 10% 0;
   width: 100%;
   height: 60%;
   object-fit: cover;
}
.our-product__card--description {
   @include flex-box (row,space-between,center,nowrap);
   padding: 0 20px;
   box-sizing: border-box;
   width: 100%;
   height: 40%;
   background-color: $neutral-color-2;
   border-radius: 0  0 10px 10px;
}
.our-product__card--description--left {
   padding-right: 20px;
   margin: 2px;
}
.our-product__card--description--right {
   font-size: 2rem;
   color: $accent-color-1;
   font-weight: 700;
}
.our-product__card--description--h5 {
   margin: 5px 0;
}
.our-product__card--description--h6 {
   margin: 5px 0;
   color: $accent-color-1;
   font-weight: 300;
}
