html {
  font-size: 90%;
  font-family: "Roboto Condensed", sans-serif;
  color: #474747;
}
html p, html h6 {
  font-size: 1rem;
  margin: 1rem 0;
  font-weight: 300;
}
html h5 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
html h4 {
  font-size: 1.96rem;
  margin: 1rem 0;
}
html h3 {
  font-size: 2.74rem;
  margin: 1rem 0;
}
html h2 {
  font-size: 3.84rem;
  margin: 1rem 0;
}
html h1 {
  font-size: 5.38rem;
  margin: 1rem 0;
}
html body {
  margin: 0;
}
html a {
  color: #474747;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

.our-product__card {
  width: 19rem; /*I removed 50px because it was a bit wide */
  height: 29rem;
  border-radius: 1rem;
  box-shadow: 0px 5px 9px -1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff;
  margin: 2.5rem 2.5rem;
}

.our-product__card--img {
  margin: 10% 0;
  width: 100%;
  height: 60%;
  -o-object-fit: cover;
     object-fit: cover;
}

.our-product__card--description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 40%;
  background-color: #ececec;
  border-radius: 0 0 10px 10px;
}

.our-product__card--description--left {
  padding-right: 20px;
  margin: 2px;
}

.our-product__card--description--right {
  font-size: 2rem;
  color: #474747;
  font-weight: 700;
}

.our-product__card--description--h5 {
  margin: 5px 0;
}

.our-product__card--description--h6 {
  margin: 5px 0;
  color: #474747;
  font-weight: 300;
}/*# sourceMappingURL=Card.css.map */