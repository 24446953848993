@use "../scss_partials/_colors" as *;
@use "../scss_partials/_global" as *;

.nav-bar {
  @include flex-box(row, space-between,center, nowrap);
  padding: 0 5%;
  box-sizing: border-box;
  width: 100vw;
  height: 12vh;
  background-color: $neutral-color-1;
  position: fixed;
  z-index: 999;
  &__items {
    @include flex-box(row, space-around,center, nowrap);
    list-style: none;
    padding: 0 0.5rem;
    &--selected {
        text-decoration: underline;
        text-decoration-color: $accent-color-2;
        text-decoration-thickness: 0.25rem;
        text-underline-offset: 0.5rem;
        padding: 0 0.5rem;
    }
   
    }
    &--anchor {
        color: $neutral-color-2;
    }
  }
  .highlight {
  color: $accent-color-2;
  font-weight: 700;
  width: 70px;
  //font-size: 0.5rem;

  }

  .border {
    border: 2px, solid, $neutral-color-1;
  }