html {
  font-size: 90%;
  font-family: "Roboto Condensed", sans-serif;
  color: #474747;
}
html p, html h6 {
  font-size: 1rem;
  margin: 1rem 0;
  font-weight: 300;
}
html h5 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
html h4 {
  font-size: 1.96rem;
  margin: 1rem 0;
}
html h3 {
  font-size: 2.74rem;
  margin: 1rem 0;
}
html h2 {
  font-size: 3.84rem;
  margin: 1rem 0;
}
html h1 {
  font-size: 5.38rem;
  margin: 1rem 0;
}
html body {
  margin: 0;
}
html a {
  color: #474747;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
}

footer {
  background-color: #ececec;
  padding-bottom: 2rem;
  padding-top: 2.5rem;
  width: 100%;
  position: relative;
  top: 12vh;
}
footer .footer__list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
footer .footer__list--subtitle {
  color: #4d0c74;
  font-size: 0.75rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
}
footer .footer__list__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  padding: 1rem;
}
footer .footer__list__card--centered {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  padding: 1rem;
}
footer .footer__list__card__sublist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  padding-left: 0;
}
footer .footer__list__card__sublist li {
  padding: 0 2rem 0 0;
}
footer .footer__list__card__sublist--icons {
  margin-top: 2.5rem;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  list-style: none;
}
footer .footer__list__card__sublist--icons img {
  height: 2rem;
}
footer .footer__list__card__sublist--icons li {
  padding: 0 1.5rem;
}

@media (max-width: 800px) {
  footer .footer__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  footer .footer__list__card {
    border: 1px solid black;
    border-radius: 5px;
    width: 90%;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  footer .footer__list__card--centered {
    border: 1px solid black;
    border-radius: 5px;
    width: 90%;
    margin: 1rem 0;
  }
}
.fullwidth {
  width: 100%;
}

.prompt {
  width: 80%;
  font-size: 1rem;
  padding: 2rem;
  justify-content: center;
  border: 1px, solid, black;
  margin: auto;
}

.anchor_format {
  font-style: italic;
  color: blue;
  text-decoration: underline;
  text-decoration-color: blue;
}/*# sourceMappingURL=Footer.css.map */