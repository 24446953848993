@use "./_colors.scss" as *;
@use "./_fonts.scss" as *;

@mixin flex-box ($direction,$justify-content,$align-items,$flex-wrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify-content;
    align-items: $align-items;
    flex-wrap: $flex-wrap;
}
body {
    margin: 0;
    padding: 0;
 }